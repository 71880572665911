@import url(//fonts.googleapis.com/css?family=Roboto:100,400,500,900);
html {
    background-color: rgba(0, 0, 0, 0.05);
}

body, html {
    padding: 0px;
    margin: 0px;
    border: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
}

p {
    margin: 0px;
}

button::-moz-focus-inner {
    border: 0;
}

img {
    max-width: 90%;
}

blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
}

code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
}
  
  

/* MUI underline effect */
.mui-underline {
    border: 0;
    background-size: 0 2px,100% 2px;
    background-repeat: no-repeat;
    background-position: center bottom,center calc(100%);
    background-color: rgba(0,0,0,0);
    -webkit-transition: background 0s ease-out;
    transition: background 0s ease-out;
    box-shadow: none;
    border-radius: 0;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
}

.mui-underline:focus {
    background-repeat: no-repeat;
    background-position: center bottom,center calc(100%);
    outline: 0;
    background-size: 100% 2px,100% 2px;
    box-shadow: none;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

/* Placeholders */
::-webkit-input-placeholder {
    color: inherit;
    font-size: inherit;
    opacity:.4;
}

:-moz-placeholder { /* Firefox 18- */
    color: inherit;
    font-size: inherit;
    opacity:.4;
}

::-moz-placeholder {  /* Firefox 19+ */
    color: inherit;
    font-size: inherit;
    opacity:.4;
}

:-ms-input-placeholder {  
    color: inherit;
    font-size: inherit;
    opacity:.4;
}

div:empty:not(:focus):before {
  content:attr(placeholder);
  color: inherit;
  font-size: inherit;
  opacity:.4;
}

[contenteditable=true]:empty:not(:focus):before {
  content:attr(placeholder);
  color: inherit;
  font-size: inherit;
  opacity:.4;
}

/* Messaging Animations */

.overlay-enter {
  opacity: 0.01;
  z-index: 100;
}

.overlay-enter.overlay-enter-active {
  -webkit-transition: opacity 50ms ease-in-out;
  transition: opacity 50ms ease-in-out;
  opacity: 1 !important;
}

.overlay-leave {
  opacity: 1;
  z-index: 100;
}

.overlay-leave.overlay-leave-active{
  opacity: 0.01;
  -webkit-transition: opacity 100ms ease-in-out;
  transition: opacity 100ms ease-in-out;
}

.dialog-appear {
  margin-top: -300px !important;
  position: relative !important;
}

.dialog-appear.dialog-appear-active {
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  margin-top: 0px !important;
}


.triage-enter {
  opacity: 0;
  margin-top: -500px !important;
  position: relative !important;
}

.triage-enter.triage-enter-active {
  opacity: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-top: 0px !important;
}

.triage-appear {
  opacity: 0;
  margin-top: -500px !important;
  position: relative !important;
}

.triage-appear.triage-appear-active {
  opacity: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-top: 0px !important;
}

.triage-leave {
  opacity: 1;
  margin-top: 0px !important;
  position: relative !important;
}

.triage-leave.triage-leave-active {
  opacity: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-top: 500px !important;
}

.card-enter {
    opacity: 1;
    top: 100vh !important;
    height: calc(100vh - 66px);
    position: fixed !important;
    left: 0px;
    width: 50% !important;
    text-align: center;
    z-index: 2;
}
  
.card-enter.card-enter-active {
    opacity: 1;
    -webkit-transition: top 300ms ease;
    transition: top 300ms ease;
    top: 66px !important;
}

.card-appear {
    opacity: 1;
    top: 100vh !important;
    height: calc(100vh - 66px);
    position: fixed !important;
    left: 0px;
    width: 50% !important;
    text-align: center;
    z-index: 2;
}

.card-appear.card-appear-active {
    opacity: 1;
    -webkit-transition: top 300ms ease;
    transition: top 300ms ease;
    top: 66px !important;
}

.card-leave {
    opacity: 1;
    top: 66px !important;
    height: calc(100vh - 66px);
    -webkit-transition: top 300ms ease;
    transition: top 300ms ease;
    position: fixed !important;
    left: 0px !important;
    width: 50% !important;
    text-align: center;
}

.card-leave.card-leave-active {
    opacity: 1;
    top: calc(-100vh + 140px) !important;
}

.reverse-enter {
    opacity: 1;
    top: calc(-100vh + 140px) !important;
    height: calc(100vh - 66px);
    position: fixed !important;
    left: 0px;
    width: 50% !important;
    text-align: center;
    z-index: 2
}
  
.reverse-enter.reverse-enter-active {
    opacity: 1;
    -webkit-transition: top 300ms ease;
    transition: top 300ms ease;
    top: 66px !important;
}

.reverse-appear {
    opacity: 1;
    top: calc(-100vh + 140px) !important;
    height: calc(100vh - 66px);
    position: fixed !important;
    left: 0px;
    width: 50% !important;
    text-align: center;
}

.reverse-appear.reverse-appear-active {
    opacity: 1;
    -webkit-transition: top 300ms ease;
    transition: top 300ms ease;
    top: 66px !important;
}

.reverse-leave {
    opacity: 1;
    top: 66px !important;
    height: calc(100vh - 66px);
    -webkit-transition: top 300ms ease;
    transition: top 300ms ease;
    position: fixed !important;
    left: 0px !important;
    width: 50% !important;
    text-align: center;
}

.reverse-leave.reverse-leave-active {
    opacity: 1;
    top: calc(100vh) !important;
}

.alerts-enter {
    bottom: -50px !important;
    z-index: 100;
    position: fixed;
    left: 0px;
}
  
.alerts-enter.alerts-enter-active {
    -webkit-transition: bottom 200ms ease-in-out;
    transition: bottom 200ms ease-in-out;
    bottom: 0px !important;
}

.alerts-leave {
    bottom: 0px !important;
    z-index: 100;
}

.alerts-leave.alerts-leave-active{
    bottom: -50px !important;
    -webkit-transition: bottom 100ms ease-in-out;
    transition: bottom 100ms ease-in-out;
}

.message-enter {
    right: -400px !important;
    z-index: 100;
    position: fixed !important;
}
  
.message-enter.message-enter-active {
    -webkit-transition: all .5s ease-in-out !important;
    transition: all .5s ease-in-out !important;
    right: 10px !important;
}

.message-appear {
    right: -400px !important;
    z-index: 100;
    position: fixed !important;
}
  
.message-appear.message-appear-active {
    -webkit-transition: all .5s ease-in-out !important;
    transition: all .5s ease-in-out !important;
    right: 10px !important;
}
  
.message-leave {
    z-index: 100;
    position: fixed !important;
    -webkit-transition: all .3s ease !important;
    transition: all .3s ease !important;
    margin-top: 0px;
}

.message-leave.message-leave-active{
    margin-top: -400px !important;
}

.prediction-context:after {
    content: attr(data-prediction);
    color:gray;
}

.slate p:after {
    content: attr(data-prediction);
    color:gray;
}

.slate div:after {
    content: attr(data-prediction);
    color:gray;
}

